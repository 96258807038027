<template>
  <div class="footer">
    <div class="footer-copyright text-center py-3">
      <div class="center-xy-ab">
        &copy; <a href="https://hawkfury.com">https://hawkfury.com</a> 鹰之怒软件版权所有&nbsp;&nbsp;&nbsp;&nbsp;技术支持(Telegram):&nbsp;<a href="https://t.me/hawkfuryGrp">https://t.me/hawkfuryGrp</a>&nbsp;&nbsp;&nbsp;&nbsp;支持邮件:&nbsp;<a href="mailto:buteo@hawkfury.com">buteo@hawkfury.com</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style>

.footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  background: #1DA1F2;
  z-index: 9999;
}

.center-xy-ab{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  white-space: nowrap;
}

a:link {
  color:black;
  text-decoration:none;
}

a:visited {
  color:black;
  text-decoration:none;
}

a:hover {
  color:black;
  text-decoration:none;
}

a:active {
  color:black;
  text-decoration:none;
}
</style>

